<template>
<div
    class="table-large overflow-x-auto relative"
    :style="cssVars"
>
    <vue-good-table
        ref="table"
        mode="remote"
        @on-page-change="$emit('page-change', $event)"
        @sort-change="$emit('sort-change', $event)"
        @on-per-page-change="$emit('per-page-change', $event)"
        @on-column-filter="onColumnFilter"
        v-bind="config"
        :sort-options="{enabled: false}"
        :totalRows="totalRows"
        :isLoading="isLoading"
        :pagination-options="paginationOptions"
        :rows="rows"
        :columns="computedColumns"
        styleClass="vgt-table bordered vgt-table-large"
    >
        <template #column-filter="{ column, updateFilters }">
          <dropdown
            v-if="column && column.config && column.config.filter"
            :ref="setFilterRef"
            reposition
            searchable
            class="filter-dropdown"
            placeholder="Select"
            :options="column.filterOptions.filterDropdownItems || null"
            :colors="{
                text: '#282828',
                bg: 'white',
                svg: '#282828',
                border: 'transparent',
            }"
            @input="(value) => updateColumnFilters(column, value, updateFilters)"
            :value="isFilters ? filters[column.field] : null"
            @search="searchFilter(column, $event)"
            :limit="1"
            minWidth="102px"
            :config="{ label: 'name', trackBy: 'id' }"
            :loading="column.config.isLoading"
          >
            <template #caret>
              <sort-icon/>
            </template>
          </dropdown>
          <div v-else>

          </div>
        </template>
        <template #table-column="props">
            <span
                v-if="props.column.config
                    && props.column.config.icon"
                class="th-col th-col--icon"
            >
                <img
                    class="icon"
                    :src="props.column.config
                        && props.column.config.icon"
                >
                {{props.column.label}}
            </span>
            <span class="th-col" v-else>
                {{props.column.label}}
            </span>
        </template>
        <template #table-row="props">
            <span v-if="props.column.config && props.column.config.type == 'link' " 
            @click="handleViewCase(props.row)" 
            class="text-primary  whitespace-nowrap  cursor-pointer hover:text-dvbrandhoveron" >{{props.formattedRow[props.column.field]}}</span>
            <div class="cursor-pointer "
                    v-else-if="props.column.config
                    && props.column.config.type == 'status'"
                    @click="$emit('handleStatusClick',props)"
            >
                <status
                    :value="props.formattedRow[props.column.field]"
                />
            </div>
            <div v-else-if="props.column.config && props.column.config.type == 'clone'" class="flex justify-center">
                <Clone @handleCloneField="$emit('handleCloneField', $event)" :row="props.formattedRow" />
            </div>
            <indicators
                v-else-if="props.column.config
                    && props.column.config.type == 'indicators'
                    && props.formattedRow[props.column.field]"
                :value="props.formattedRow[props.column.field]"
            />
            <span v-else>
                {{props.formattedRow[props.column.field]}}
            </span>
        </template>
        <template #emptystate>
            <div class="alert alert-info my-5">
                No Data Found
            </div>
        </template>
    </vue-good-table>
    <button
        v-if="isFilters"
        class="clear-filters cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute top-2 left-2 z-50"
        @click="clearFilters"
    >
        Clear filters
    </button>
</div>
</template>

<script>
import status from './status';
import indicators from './indicators';
import dropdown from '@/components/dropdown-base';
import sortIcon from '@/assets/icons/dashboard/dropdown-double.svg';
import { onlyUnique} from '@/plugins/utils.js';
import { uuid } from "vue-uuid";
import Clone from "./clone.vue"
// import { isEqual } from 'lodash';

export default {
    name: 'table-large',
    components: {
        status,
        indicators,
        dropdown,
        sortIcon,
        Clone,
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
        /* Set config: {type, icon} to use particular component in td or add icon to th */
        columns: {
            type: Array,
            required: true,
        },
        rows: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
            default: null,
        },
        filterOptions: {
            type: Object,
            default: null,
        },
        filterOptionsAsync: {
            type: Object,
            default: null,
        },
        totalRows: {
            type: Number,
            default: 0,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        colorHeader: {
            type: String,
            default: '#029ADB',
        },
        colorRow: {
            type: String,
            default: '#F2F2F2',
        },
        colorBorder: {
            type: String,
            default: '#D9D9D9',
        },
        colorTextHeader: {
            type: String,
            default: '#FFF',
        },
        handleCaseClick:{
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            paginationOptions: {
                enabled: true,
                dropdownAllowAll: false,
            },
            filterRefs: [],
        }
    },
    // watch: {
    //     filters(val) {
    //         console.log('watch filters', val);
    //     },
    // },
    computed: {
        isFilters() {
            return this.filters && Object.entries(this.filters).length;
        },
        computedColumns() {
            // use option list from server if it is:
            const options = this.filterOptionsAsync || this.filterOptions;
            if (options) {
                return this.columns.map(col => {
                    if(col.prefetchedOptions && col.prefetchedOptions.length){
                        return {
                            ...col,
                            filterOptions: {
                                enabled: true,
                                filterDropdownItems: col.prefetchedOptions,
                            },
                        }
                    }else{
                        return {
                            ...col,
                            filterOptions: {
                                enabled: true,
                                filterDropdownItems: options[col.field],
                            },
                        }
                    }
                });
            } else { // TODO remove/rework default filters
                return this.columns.map(col => {
                    let filtered = col.config?.filter || false
                    return {
                        ...col,
                        filterOptions: filtered ? {
                            enabled: true,
                            placeholder: 'All',
                            filterDropdownItems: this.rows
                                .filter(row => row[col.field])
                                .map(row => row[col.field])
                                .filter(onlyUnique)
                                .map(el => ({id: uuid.v4(), name: el}))
                        } : undefined,
                    }
                });
            }
        },
        cssVars() {
            return {
                '--color-header': this.colorHeader,
                '--color-row': this.colorRow,
                '--color-border': this.colorBorder,
                '--color-text-header': this.colorTextHeader,
            }
        }
    },
    methods:{
        onColumnFilter(e) {
            console.log('onColumnFilter', e);
            // const { columnFilters } = e;
            // if (!isEqual(this.filters, columnFilters)) this.$emit('column-filter', e);
        },
        clearFilters() {
            this.$emit('filters-clear');
            // this.filterRefs.forEach((el) => {
            //     el.changeValue(null);
            // });
        },
        handleViewCase(caseData){
            this.handleCaseClick(caseData)
        },
        searchFilter(column, value) {
            this.$emit('search-filter', {column, value});
        },
        setFilterRef(el) {
            if (el) {
                this.filterRefs.push(el)
            }
        },
        updateColumnFilters(column, value/* , callback */) {
            // Table filters form status and case status takes list of IDs as  param,
            // whereas other takes strings (name)
            // if (['form_status','case_status' ].includes(column.field)) {
            //     newValue = value.id
            // } else {
            //     newValue = value.name
            // }
            this.$emit('column-filter', {
                columnFilters: {
                    ...this.filters,
                    [column.query_key || column.field]: value,
                }
            });
        },  
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/mixins.scss';

* {
    box-sizing: border-box;
}

::v-deep {
    .vgt-responsive {
        @include scrollBar;
    }
    .vgt-table-large {
        // margin-bottom: 20px;
        border-radius: 16px;
        overflow: hidden;
        thead {
            tr:not(:first-child) {
                &:nth-child(even) {
                    th {
                        background-color: var(--color-row);
                    }
                }
            }
        }
        thead th {
            // background: var(--color-header);
            background: unset;
            @apply bg-thead;
            text-align: center;
            vertical-align: middle;
            span {
                // color: var(--color-text-header);
                @apply text-white-text;
                font-size: 16px;
            }
            .th-col {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
            }
            .th-col--icon {
                .icon {
                    width: 22px;
                    height: 22px;
                }
            }
            // Custom th classes:
            &.nowrap {
                .th-col {
                    white-space: nowrap;
                }
            }
        }
        tr {
            border-left: 2px solid #DCDFE6;
            border-right: 2px solid #DCDFE6;
        }
        tr, td, th {
            border-color: var(--color-border)!important;
        }
        tbody {
            font-size: 14px;
            // border-bottom: 20px solid var(--color-header);
            border-bottom-width: 20px;
            border-bottom-style: solid;
            @apply border-thead;
            tr {
                &:nth-child(even) {
                    background-color: var(--color-row);
                }
                td {
                    font-weight: bold;
                    vertical-align: middle;
                    text-align: center;
                }
            }
        }
    }
    .vgt-inner-wrap {
        box-shadow: none;
    }
    .vgt-wrap__footer {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
}
</style>
