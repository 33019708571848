import tabs from "@/components/dashboard-components/tabs";
import emailTempModal from "@/components/emailtemplatemodal";
import searchModal from "@/components/search-modal";
import emailSequence from "./email-sequence";
import emailTemplate from "./email-template";
import BrowseDir from "@/components/browse-dir";
import Sequence from "@/components/sequence";
export default {
  name: "email-admin",
  components: {
    tabs,
    emailTempModal,
    "email-sequence": emailSequence,
    "email-template": emailTemplate,
    searchModal,
    BrowseDir,
    Sequence,
  },
  data() {
    return {
      fakeData: {
        tabs: {
          selected: "templates",
          list: [
            {
              id: "templates",
              label: "Templates",
            },
            {
              id: "sequences",
              label: "Sequence",              
            },
          ],
        },
      },
      isModalOpen: false,
      isDirModalOpen: false,
      isSearchModal: false,
      isSequenceModal: false,
    };
  },
  title: "Email Admin",
  methods: {
    async selected() {
      this.isSearchModal = false;
      this.isSequenceModal = true;
    },
    selectTab(id) {
      this.fakeData.tabs.selected = id;
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: this.fakeData.tabs.selected,
        },
      })
    },
    showDetails() {
      this.showModal = true;
    },
    EmailTempModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    BrowseDirModal() {
      this.isDirModalOpen = !this.isDirModalOpen;
    },
    SearchModal() {
      this.isSearchModal = !this.isSearchModal;
    },
    onModelClose() {
      this.isModalOpen = !this.isModalOpen;
    },
    onDirModalClose() {
      this.isDirModalOpen = !this.isDirModalOpen;
    },
    onSearchModalClose() {
      this.isSearchModal = !this.isSearchModal
    }
  },
  props: {
    CreateEmailTempModal: Function,
  },
  mounted() {
    this.fakeData.tabs.selected = this.$route?.query?.tab || "templates";
  }
};
